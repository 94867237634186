
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import ImageView from '@/components/ImageView.vue'
import PageUtil from "@/utils/page_util";
import ApiAdmin from "@/request/apis/api_admin";
import {AuthMenu} from "@/models/menu_model";

interface IState {
  list: AuthMenu[],
  total: number;
  loading: boolean;
  searches: any
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '权限名称',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '类型',
    dataIndex: 'type',
    key: 'type',
    width: 100
  },
  {
    title: '跳转地址',
    dataIndex: 'front_url',
    key: 'front_url',
  },
  {
    title: '权限标识',
    dataIndex: 'uri',
    key: 'uri',
  },
  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
  },
  {
    title: '状态',
    dataIndex: 'disable',
    key: 'disable',
    width: 100
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    // fixed: 'right',
    width: 220
  }
];

export default defineComponent({
  name: 'AdminPermission',
  components: {ImageView},
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      category: [],
      subCategory: [],
      editItem: undefined,
      searches: {
        keywords: '',
        pid: '',
        cid: '',
        c_id: '',
        is_recommend: '',
        type: '',
        is_show: '',
        page: PageUtil.pageNo,
        limit: PageUtil.pageSize
      }
    })


    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
      hideOnSinglePage: false
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      StorageUtil.setParams(params)
      delete params.total
      delete params.pid
      state.loading = true
      const res = await ApiAdmin.getAllMenus(params);
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0;

        state.list = res.data?.lists || [];
        state.list = exchangeListData(state.list);
      }
    }

    // 转换筛选list数据
    const exchangeListData = (list:AuthMenu[]) => {
      list.map(item => {
        item.sub = item.sub && item.sub.length ? item.sub : undefined;
        if (item.sub) {
          item.sub = exchangeListData(item.sub);
        }
      })
      return list;
    }


    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      if (key === 'keywords') {
        for (let key in state.searches) {
          state.searches[key] = ''
        }
      } else {
        state.searches.keywords = ''
        if (key === 'pid') {
          state.searches.cid = value
          state.searches.c_id = ''
          if (value) {
            // state.subCategory = state.category.find(item => item.id === value)?.children || []
          } else {
            // state.subCategory = []
          }
        }
        if (key === 'c_id') {
          if (value) {
            state.searches.cid = value
          } else {
            state.searches.cid = state.searches.pid
          }
        }
      }
      state.searches[key] = value
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      // state.subCategory = []
      getList()
    }

    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    // 添加或修改菜单
    const addOrUpdateMenu = async (item?: AuthMenu) => {
      let params = {}
      console.log(item)
      if (item) {
        params = {id: item.id, data: JSON.stringify(item)}
      }
      router.push({path: '/setting/admin-permission-set', query: params}).then()
    };

    // 删除菜单
    const deleteMenus = async (item: AuthMenu) => {
      if (!item.id) return
      state.loading = true
      const res = await ApiAdmin.deleteMenu([item.id as number]);
      state.loading = false
      message.destroy()
      if (res && res.error_code === 0) {
        getList()
        return
      }
      message.error(res.msg);
    };

    // 启用菜单
    const changeMenuStatus = async (item: AuthMenu) => {
      state.loading = true;
      item.disable = item.disable ? 0: 1;
      const res = await ApiAdmin.updateMenu(item);
      state.loading = false
      message.destroy();
      if (res && res.error_code === 0) {
        getList()
        return
      }
      message.error(res.msg);
    };

    return {
      ...toRefs(state),
      columns,
      pagination,
      handleTableChange,
      onSearch,
      resetSearch,
      addOrUpdateMenu,
      deleteMenus,
      changeMenuStatus
    }
  }
})
